import request from './request'

export default {
    /**
     * 查询轮播图
     * @returns {AxiosPromise}
     */
    getBannerList() {
        return request({
            url: '/banner/list',
            method: 'post'
        })
    },
    /**
     * 查询创作历史
     * @returns {AxiosPromise}
     */
    getRecordList() {
        return request({
            url: '/record/list',
            method: 'post'
        })
    },
    /**
     * 查询文章
     * @returns {AxiosPromise}
     */
    getArticleList() {
        return request({
            url: '/article/list',
            method: 'post'
        })
    },
    /**
     * 登录
     * @returns {AxiosPromise}
     */
    login(data) {
        return request({
            url: '/user/login',
            method: 'post',
            data: data
        })
    },
    /**
     * 退出登录
     * @returns {AxiosPromise}
     */
    logout() {
        return request({
            url: '/user/logout',
            method: 'post'
        })
    },
    /**
     * 获取用户信息
     * @returns {AxiosPromise}
     */
    userInfo() {
        return request({
            url: '/user/userInfo',
            method: 'post'
        })
    }


}
