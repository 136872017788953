<template>
  <div>
    <el-container>
      <el-header>
        <el-row>
          <el-col :span="16" :offset="4">
            <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
              <el-menu-item index="/">首页</el-menu-item>
              <el-menu-item index="/interview/material">知识库</el-menu-item>
<!--              <el-menu-item index="/personal">个人笔记</el-menu-item>-->
            </el-menu>
          </el-col>
          <el-col :span="2" :offset="2">
            <div style="display: flex;flex-direction: row-reverse;height: 61px;align-items: center;">
              <el-button type="text" v-if="!userInfo.name" @click="dialogFormVisible=true">登录</el-button>
              <el-dropdown v-if="userInfo.name">
                <span class="el-dropdown-link">
                  {{userInfo.name}}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>个人信息</el-dropdown-item>
                  <el-dropdown-item>
                    <div @click="logout()">退出登录</div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </el-col>
        </el-row>
      </el-header>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
    <div style="height: 100px;width: 100%;background-color: #F2F6FC;display: flex;justify-content: center;align-items: center;">
      <a href="https://beian.miit.gov.cn/index.vue#/Integrated/index" target="_blank">蜀ICP备19011731号</a>
    </div>

    <!-- 弹框 -->
    <div>
      <!-- 登录弹框 -->
      <el-dialog title="登录" :visible.sync="dialogFormVisible" width="30%">
        <el-form :model="loginForm">
          <el-form-item label="账户" :label-width="formLabelWidth">
            <el-input v-model="loginForm.account" autocomplete="off" clearable></el-input>
          </el-form-item>
          <el-form-item label="密码" :label-width="formLabelWidth">
            <el-input v-model="loginForm.password" autocomplete="off" clearable show-password></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="login()">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import homeApi from "@/api/home"
import token from "@/api/token"
export default {
  name: "index",
  data() {
    return {
      activeIndex: '/',
      userInfo: {
        id: '',
        createTime: '',
        updateTime: '',
        account: '',
        name: '',
        type: '',
        state: ''
      },
      dialogFormVisible: false,
      loginForm: {
        account: 'youke9527',
        password: '123456'
      },
      formLabelWidth: '80px'
    }
  },
  created() {
    if (token.getToken()) {
      this.userInfoM()
    }
    this.handleSelect(window.location.pathname)
    this.activeCheck(window.location.pathname)
  },
  methods: {
    activeCheck(url) {
      switch (url) {
        case '/interview/material':
        case '/interview/test':
        case '/interview/testRecord':
        case '/interview/wrongQuestionBank':
          this.activeIndex = '/interview/material'
          break
        default:
          this.activeIndex = window.location.pathname
          break
      }
    },
    handleSelect(key) {
      this.activeIndex = key
      this.$router.push({ path: key })
    },
    login() {
      homeApi.login(this.loginForm).then(res => {
        if (res.code === 200) {
          this.message(res.message)
          token.setToken(res.data)
          this.userInfoM()
          this.dialogFormVisible = false
        }
      })
    },
    logout() {
      this.$confirm('是否要退出', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then( () => {
        homeApi.logout().then(res => {
          if (res.code === 200) {
            token.removeToken()
            location.reload()
          }
        })
      });
    },
    userInfoM() {
      homeApi.userInfo().then(res => {
        if (res.code === 200) {
          this.userInfo = res.data
        }
      })
    },
    /**
     * 消息提示
     * @param code
     * @param message
     */
    message(message) {
      this.$message({
        message: message,
        type: 'success'
      })
    },
  }
}
</script>

<style scoped>
  .el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
</style>
