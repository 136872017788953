export default {
    /**
     * 获取token
     * @returns {string}
     */
    getToken() {
     return localStorage.getItem("token-user");
    },
    /**
     * 添加token
     * @param data
     */
    setToken(data) {
      localStorage.setItem("token-user", data)
    },
    /**
     * 删除token
     */
    removeToken() {
      localStorage.removeItem("token-user")
    }
}
