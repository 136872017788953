<template>
  <div id="app">
    <Index/>
  </div>
</template>

<script>
import Index from './components/index'

export default {
  name: 'App',
  components: {
    Index
  }
}
</script>

<style>
</style>
