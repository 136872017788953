import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)


export const constantRoutes = [
  {
    path: '/404',
    component: () => import('@/components/404'),
    hidden: true
  },
  // {
  //   path: '/',
  //   component: Layout,
  //   redirect: '/dashboard',
  //   children: [{
  //     path: 'dashboard',
  //     name: 'Dashboard',
  //     component: () => import('@/components/index'),
  //     meta: { title: 'Dashboard', icon: 'dashboard' }
  //   }]
  // },
  {
    path: '/',
    component: () => import('@/components/home')
  },
  // {
  //   path: '/interview',
  //   component: () => import('@/components/interview/interview')
  // },
  // {
  //   path: '/interview',
  //   component: Layout,
  //   children: [
  //     {
  //       path: '/',
  //       component: () => import('@/components/interview/knowledge/study')
  //     },
  //     {
  //       path: '/test',
  //       component: () => import('@/components/interview/knowledge/test')
  //     }
  //   ]
  // },
  {
    path: '/interview',
    component: () => import('@/components/interview/index'),
    children: [
      {
        path: 'material',
        component: () => import('@/components/interview/knowledge/material')
      },
      {
        path: 'test',
        component: () => import('@/components/interview/knowledge/test')
      },
      {
        path: 'testRecord',
        component: () => import('@/components/interview/knowledge/testRecord')
      },
      {
        path: 'wrongQuestionBank',
        component: () => import('@/components/interview/knowledge/wrongQuestionBank')
      }
    ]
  },
  {
    path: '/personal',
    component: () => import('@/components/personal')
  },
  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/404', hidden: true }
]

const createRouter = () => new Router({
  mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
